.list-with-pagination {
    margin-top: 20px;
    font-family: 'Arial', sans-serif;
    color: black; 
  }
  
  .pagination-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
  
  .loading-text {
    font-size: 16px;
    color: #666;
  }
  
  .items-per-page {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .items-label {
    font-weight: bold;
  }
  
  #items-per-page-dropdown {
    min-width: 60px;
  }
  .list-with-pagination {
    margin-top: 20px;
    font-family: 'Arial', sans-serif;
    color: black;
    position: relative;
  }
  
  .list-container {
    transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
    opacity: 1;
    transform: translateY(0);
  }
  
  .list-container.refreshing {
    opacity: 0.5;
    transform: translateY(-10px);
  }
  
  .loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
  }
  
  .loading-overlay .spinner-border {
    width: 3rem;
    height: 3rem;
  }
  
  .pagination-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
  }
  
  .items-per-page {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .items-label {
    font-weight: bold;
  }
  
  #items-per-page-dropdown {
    min-width: 60px;
  }
  

body {
    margin: 0;
    padding: 0;
    background-color: #f4f4f4; 
    font-family: 'Arial', sans-serif;
  }
  
  
  .login-container {
    background-color: #f4f4f4; 
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 0 20px; 
  }
  
  
  .login-content {
    background: linear-gradient(135deg, #CE1126, #E63946); 
    border-radius: 15px;
    padding: 2rem 2.5rem; 
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1); 
    text-align: center;
    color: #fff;
    width: 100%;
    max-width: 450px; 
  }
  
  .login-logo {
    max-width: 180px; 
    margin-bottom: 1.5rem;
  }
  
  .login-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1.2rem;
    color: #fff;
    line-height: 1.4;
  }
  
  .login-description {
    font-size: 1.1rem;
    margin-bottom: 2rem;
    color: #fff;
    max-width: 350px;
    line-height: 1.6;
    margin-left: auto;
    margin-right: auto;
  }
  
  
  .login-button {
    background-color: #fff; 
    color: #CE1126; 
    border: none;
    padding: 0.8rem 2rem; 
    font-size: 1.2rem;
    font-weight: bold;
    border-radius: 30px; 
    transition: all 0.3s ease;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); 
  }
  
  .login-button:hover {
    background-color: #E63946; 
    color: #fff; 
    transform: scale(1.05); 
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); 
  }
  
  .login-button:focus {
    outline: none;
  }
  




.custom-login-button {
    background-color: #fff !important; 
    color: #CE1126 !important;
    border: none !important;
    padding: 0.8rem 2rem !important;
    font-size: 1.2rem !important;
    font-weight: bold !important;
    border-radius: 30px !important;
    transition: all 0.3s ease !important;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2) !important;
  }
  
  
  .custom-login-button:hover {
    background-color: #E63946; 
    color: #fff; 
    transform: scale(1.05); 
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2); 
  }
  
  .custom-login-button:focus {
    outline: none;
  }

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  
  body {
    font-family: 'Arial', sans-serif;
    background-color: #f5f5f5; 
    color: #fff 
  }
  
  .login-page {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
  }
  
  .login-header {
    background-color: #ce1126; 
    padding: 20px 0;
    text-align: center;
  }
  
  .login-logo {
    max-width: 200px;
    margin: 0 auto;
    display: block;
  }
  
  .login-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .login-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    color: #333;
  }
  
  .custom-login-button {
    background-color: #fff;
    color: #ce1126;
    border: none;
    padding: 1rem 3rem;
    font-size: 1.2rem;
    font-weight: bold;
    border-radius: 30px;
    transition: all 0.3s ease;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
  
  .custom-login-button:hover {
    background-color: #e63946;
    color: #fff;
    transform: scale(1.05);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  }
  
  .custom-login-button:focus {
    outline: none;
  }
  
  .login-footer {
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 10px;
    font-size: 0.9rem;
  }
  
  .login-footer p {
    margin: 0;
  }
  
.custom-navbar {
    background-color: #d31b1b; 
  }
  
  .custom-container {
    color: #fff;
    background-color: #f5f5f5; 
    border-radius: 8px;
    padding: 20px;
  }
  
  .logo {
    height: 40px;
  }
  
  .pagination .page-link {
    color: #d31b1b;
  }
  
  .pagination .active .page-link {
    background-color: #d31b1b;
    border-color: #d31b1b;
    color: #fff;
  }
  
  .dashboard-container {
    padding: 20px;
    background-color: #f9f9f9; 
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .dashboard-title {
    font-size: 24px;
    color: #333;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  .custom-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .table-header {
    background-color: #343a40; 
    color: #fff;
  }
  
  .table-row:nth-child(even) {
    background-color: #f2f2f2; 
  }
  
  .table-row:hover {
    background-color: #e9ecef; 
  }
  
  .table-bordered td, .table-bordered th {
    border: 1px solid #dee2e6; 
  }
  .filters-container {
    border: 1px solid #ddd;
    padding: 1rem;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .filter-container input {
    width: 100%;
  }
  
  .custom-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .d-flex {
    display: flex;
  }
  
  .gap-2 {
    gap: 0.5rem;
  }
  

  .pagination-controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 1rem;
  }
  
  .items-per-page {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
  
  .items-per-page select {
    padding: 0.25rem 0.5rem;
  }
 

.custom-navbar {
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: #d31b1b; 
}

.custom-navbar .navbar-brand img {
  height: 40px;
}


.custom-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f8f9fa; 
  border-radius: 8px;
}


.text-red-shadow {
  color: #d31b1b; 
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.4); 
  font-weight: bold;
}


.filter-button-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.filters-container {
  background-color: #f8f9fa; 
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 1rem;
}

.filters-container h2,
.filters-container button {
  color: #d31b1b;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}


.list-wrapper {
  background-color: #f8f9fa; 
  color: #212529; 
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
}

.list-wrapper h2 {
  color: #d31b1b; 
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); 
}

/* Tabla */
.table-row:nth-child(even) {
  background-color: #f2f2f2;
}

.table-row:hover {
  background-color: #e9ecef;
}


.custom-navbar .nav-link {
  color: #ffffff !important;
  font-weight: bold; 
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); 
}

.custom-navbar .navbar-brand {
  color: #ffffff !important; 
  font-weight: bold;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); 
}

.custom-navbar .offcanvas-title {
  color: #ffffff !important; 
}


.custom-navbar .navbar-toggler-icon {
  filter: brightness(0) invert(1); 
}
.login-footer {
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 10px;
  font-size: 0.9rem;
}

.login-footer p {
  margin: 0;
}


/* Navbar */
.custom-navbar {
  background-color: #d31b1b;
  position: sticky;
  top: 0;
  z-index: 1000;
}

/* Footer */
.login-footer {
  background-color: #333;
  color: #fff;
  text-align: center;
  padding: 10px;
  font-size: 0.9rem;
  margin-top: 20px;
}

/* List Container */
.custom-container {
  max-width: 100%;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
}

/* Filters Modal */
.filter-button {
  font-size: 1rem;
}

.text-red-shadow {
  color: #d31b1b;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
}

/* Responsive */
@media (max-width: 576px) {
  .filter-button {
    font-size: 0.8rem;
  }

  .text-red-shadow {
    font-size: 1.5rem;
  }
}
